import { Form, Input, Spin, Button, Select, Modal, message } from "antd"
import Quiz from './quiz'
import Vocabulary from './vocabulary'
import { useEffect, useState } from "react"
import http from '../../lib/http'
import * as apis from '../../lib/api'
import Upload from '../../components/upload'
import { cloneDeep, set } from "lodash"
import { useNavigate } from "react-router-dom";

const genres = [
  {
    "label": "Informative",
    "value": "Informative"
  },
  {
    "label": "Persuasive",
    "value": "Persuasive"
  },
  {
    "label": "Descriptive",
    "value": "Descriptive"
  },
  {
    "label": "Narrative",
    "value": "Narrative"
  },
  {
    "label": "Report",
    "value": "Report"
  },
  {
    "label": "poetry",
    "value": "poetry"
  },
  {
    "label": "Story",
    "value": "Story"
  },
  {
    "label": "Blog Post",
    "value": "Blog Post"
  },
  {
    "label": "Email",
    "value": "Email"
  },
  {
    "label": "Letter",
    "value": "Letter"
  }
]

export const GradeOptions = [
  {
    label: "1st Grade",
    value: "1st Grade"
  },
  {
    label: "2nd Grade",
    value: "2nd Grade"
  },
  {
    label: "3rd Grade",
    value: "3rd Grade"
  },
  {
    label: "4th Grade",
    value: "4th Grade"
  },
  {
    label: "5th Grade",
    value: "5th Grade"
  },
  {
    label: "6th Grade",
    value: "6th Grade"
  },
  {
    label: "7th Grade",
    value: "7th Grade"
  },
  {
    label: "8th Grade",
    value: "8th Grade"
  },
  {
    label: "9th Grade",
    value: "9th Grade"
  },
  {
    label: "10th Grade",
    value: "10th Grade"
  },
  {
    label: "11th Grade",
    value: "11th Grade"
  },
  {
    label: "12th Grade",
    value: "12th Grade"
  }
]

const Index = () => {
  const { detail, setDetail } = useState({})
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const n = useNavigate()
  const content = Form.useWatch("content", form)
  const lexile = Form.useWatch("lexile", form)

  const toValue = (str) => {
    const synonyms = []
    const arr = str.split(",")
    // for (let i = 0; i < arr.length; i++) {
    //   synonyms.push({
    //     value: arr[i],
    //     label: arr[i],
    //   })
    // }
    return arr
  }

  const loadData = async (hashId) => {
    try {
      const data = await http.get(`${apis.WRITING_TASK_DETAIL}?task_id=${hashId}`)
      if (data.avatar) {
        data.avatar = [{ path: data.avatar, thumbUrl: process.env.REACT_APP_OSS_URL + "/" + data.avatar }]
      } else {
        data.avatar = []
      }
      if (data.grades) {
        data.grades = data.grades.split(",")
      } else {
        data.grades = []
      }
      if (data.imgs) {
        const imgs = []
        data.imgs = JSON.parse(data.imgs)
        for (let i of data.imgs) {
          imgs.push({ path: i, thumbUrl: process.env.REACT_APP_OSS_URL + "/" + i })
        }
        data.imgs = imgs
      } else {
        data.img = []
      }
      console.log("data", data)
      form.setFieldsValue(data)
    } catch (error) {

    }
  }


  useEffect(() => {
    const search = window.location.search
    const param = new URLSearchParams(search)
    if (param.get("task_id")) {
      setLoading(true)
      loadData(param.get("task_id")).then(() => {
        setLoading(false)
      })
    }
  }, [])

  const onSubmit = async (values) => {
    const cpV = cloneDeep(values)
    if (cpV.avatar && cpV.avatar.length !== 0) {
      cpV.avatar = cpV.avatar[0].path
    } else {
      cpV.avatar = ''
    }
    if (cpV.imgs && cpV.imgs.length !== 0) {
      const paths = []
      for (let i of cpV.imgs) {
        paths.push(i.path)
      }
      cpV.imgs = JSON.stringify(paths)
    } else {
      cpV.imgs = "[]"
    }
    if (cpV.grades && cpV.grades.length !== 0) {
      cpV.grades = cpV.grades.join(",")
    } else {
      cpV.grades = "[]"
    }
    setLoading(true)
    try {
      if (cpV.task_id) {
        await http.post(apis.WRITING_TASK_UPDATE, cpV)
      } else {
        await http.post(apis.WRITING_TASK_CREATE, cpV)
      }
      message.success("操作成功")
      n("/writing/list")
      // if (cpV.hash_id) {
      //   Modal.confirm({
      //     content: "更新成功",
      //     okText: "预览",
      //     cancelText: "返回列表",
      //     onOk: () => {
      //       window.open(`${process.env.REACT_APP_FRONT_URL}/article/${cpV.hash_id}`)
      //     },
      //     onCancel: () => {
      //       n("/article/list")
      //     }
      //   })
      // } else {
      //   Modal.confirm({
      //     content: "新建成功",
      //     okText: "预览",
      //     cancelText: "返回列表",
      //     onOk: () => {
      //       window.open(`${process.env.REACT_APP_FRONT_URL}/article/${ret.hash_id}`)
      //     },
      //     onCancel: () => {
      //       n("/article/list")
      //     }
      //   })
      // }
    } catch (error) {

    }
    setLoading(false)
  }

  return (
    <Spin spinning={loading}>
      <div style={{ overflow: "auto", height: "calc(100vh - 232px)" }}>
        <Form form={form} onFinish={onSubmit}>
          <Form.Item hidden name="task_id">
            <Input />
          </Form.Item>
          <Form.Item rules={[{ required: true }]} label="年级" name="grades">
            <Select mode="multiple" placeholder="请选择" options={GradeOptions}></Select>
          </Form.Item>
          <Form.Item rules={[{ required: true }]} label="类型" name="genre">
            <Select placeholder="请选择" options={genres}></Select>
          </Form.Item>
          <Form.Item label="图片" name="imgs">
            <Upload maxCount={10} />
          </Form.Item>
          <Form.Item rules={[{ required: true }]} label="任务内容" name="content">
            <Input.TextArea rows={20} placeholder="请输入" />
          </Form.Item>
          <Form.Item>
            <div style={{ textAlign: "center", marginTop: 12 }}>
              <Button type="primary" htmlType="submit">提交</Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </Spin>
  )
}

export default Index